import * as React from 'react';

const NotFoundPage: React.SFC<{}> = () => (
  <div>
    <h1>404</h1>
    <p>Not found</p>
  </div>
);

export default NotFoundPage;
